@font-face{
  font-family:CircularStd;
  src: url('assets/fonts/CircularStdBook.ttf')
}
@font-face{
  font-family:CircularStd;
  font-weight:bold;
  src: url('assets/fonts/CircularStdBold.ttf')  
}
@font-face{
  font-family:ProximaNova;
  src: url('assets/fonts/ProximaNova-Regular.otf')  
}
@font-face{
  font-family:CPIcons;
  src: url('assets/fonts/cp-fonts/creditplus_font_icons-regular-webfont.woff2') format('woff2'),
        url('assets/fonts/cp-fonts/creditplus_font_icons-regular-webfont.woff') format('woff');
}

body{
  background-color: #fbfbfb;
  margin:0;
  font-weight: 400;
  line-height: 1.5;
  width:100vw;
  overflow-x:hidden;
  color: #203757;
  font-family: 'CircularStd';
}

.fixed-header{
  box-sizing:border-box;
}

.fixed-header-width {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
.fixed-page-title-vehicle-serarch {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  max-width: 447px;
  margin: 0 auto;
  margin-bottom: 20px;
}

@media(max-width:499px) {
  .fixed-page-title-vehicle-serarch {
    width: 88%;
  }

  .fixed-page-title-vehicle-serarch-backbtn {
    width: 100%;
  }
}



.main-body{
  width: 100vw;
  padding-left: 30px;
  padding-right: 30px;
  font-family: "CircularStd";
  color: #203757;
  margin: 0 auto;
  overflow-x:hidden;
  box-sizing:border-box;
}
.main-body-narrow-padding{
  width: 100vw;
  padding-left:20px;
  padding-right:20px;
  font-family: "CircularStd";
  color:#203757;
  margin: 0 auto;
  max-width:500px;
  overflow-x:hidden;
  box-sizing:border-box;
}

.screen-height-scroller-container{
  height:100vh;
  width:100vw;
  overflow-y:scroll;
  overflow-x:hidden;
}

.centred-content {
  text-align: center;
}

.desktop-only-content{
  display:none;
}

.desktop-welcome{
  width: 100%;
  margin-top: 30px;
  font-size: 20px;
}

.desktop-logo{
  width: 240px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.partner-message{
  margin-top: 13px;
  font-size:14px;
}

.partner-message img{
  height: 18px;
  margin-top:-3px
}

.page-title{
  font-size:30px;
  float:left;
  font-weight:bold;
}

.fixed-page-title {
  font-size: 30px;
  font-weight: bold;
  margin-left: 29.5%;
  margin-bottom: 20px;
}

.medium-title{
  font-size:22px;
  margin: 0 auto;
  margin-top:30px;
  margin-bottom:50px;
  max-width: 752px;
}

// control position of password show eyeball
.eye{
  position: absolute;
    z-index: 5;
    font-size: 30px;
    margin-left: -33px;
    margin-top: 2px;
    color: #79879A;
    font-family: 'CPIcons';
}

@media(hover: hover) and (pointer: fine) {
.eye:hover{
  color: #c50084;
}
}

.desktop-only{
  display:none;
}

.landscape-mobile-only{
  display:none;
}

@media (min-width: 700px){
  @media (min-height: 500px){
    .desktop-only-content {
      display: block;
    }

    .mobile-only {
      //    display:none;
      .card-carousel-container {
        margin-left: auto;
        margin-right: auto;
      }
    }

  .desktop-only{
    display:block;
  }

  .medium-title{
    font-size:27px;
  }
  }
  @media(max-height:499px){
    .mobile-only {
      //      display:none;
    }

    .desktop-only{
      display:none;
    }

    .landscape-mobile-only{
      display:block;
    }
  }
}

@media (min-width: 1100px){

  .medium-title{
    font-size:36px;
    max-width:fit-content;
  }
}

.raised-back-button{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.22);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 0px;
  margin-bottom: 20px;
  margin-left: 7px;
  background-color: white;
  font-family: 'CPIcons';
  font-size: 34px;
  padding-left: 6px;
  overflow: hidden;
  box-sizing:border-box;
  display:inline-block;
  user-select:none;
}

.raised-back-button-icon{
  margin-top: -6.5px;
  margin-left: 0.5px;
}

.standard-button{
  background-color: #c50084;
    color: white;
    border: 1px solid #c50084;
    border-radius: 4px;
    width: 100%;
    max-width: 410px;
    height: 30px;
    text-align: center;
    padding-top: 4px;
    font-size: 15px;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
    transition: color 0.5s;
    transition: background-color 0.5s;
    font-family: 'CircularStd';
    box-sizing:border-box;
}

.standard-button:hover{
  background-color:white;
  color: #c50084;
  cursor:pointer;
}

.disabled-button{
  opacity:0.5;
}

.disabled-button:hover{
  background-color: #c50084;
    color: white;
    cursor:default;
}

.back-button-arrow{
  color:#c50084;
  margin-left: 10px;
  margin-top: 9.5px;
}

.back-button-arrow-image{
  margin-left: 5px;
  margin-top: 6px;
  height: 28px;
}

.raised-card{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
  padding: 20px;
  border-radius:5px;
  max-width: 678px;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color:white;
  position:relative;
}

.raised-card-vertical{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
  padding: 5px;
  border-radius: 5px;
  min-width: 128px !important;
  max-width: 128px !important;
  margin-bottom: 20px;
  margin-right: 10px;
  overflow: hidden;
  padding-bottom: 12px;
  background-color:white;
  padding-top:0px;
}

.raised-card-horizontal{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
  padding: 5px;
  border-radius: 5px;
  min-width: 190px !important;
  max-width: 190px !important;
  margin-bottom: 20px;
  margin-right: 10px;
  padding-bottom:8px;
  padding-top:0px;
  background-color:white;
}

.horizontal-card-image{
  height: 120px;
  width: calc(100% + 10px);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  margin-bottom: 12px;
  margin-left: -5px;
}

.horizontal-card-image img{
  float: left;
  width:100%;
}

.card-carousel-container{
  width: calc(100vw + 30px);
  max-width: 678px;
  margin-left: -30px;
  overflow: hidden;
  margin-right: -30px;
}

.left-carousel-fade{
  position: relative;
  width: 30px;
  height: 280px;
  margin-bottom: -280px;
  margin-right: -20px;
  background: linear-gradient(to right, #fbfbfb 50%, rgba(251, 251, 251, 0) 100%);
  pointer-events: none;
  z-index: 2;
}

.right-carousel-fade{
  position: relative;
  float: right;
  width: 30px;
  height: 280px;
  margin-bottom: -280px;
  background: linear-gradient(to left, #fbfbfb 50%, rgba(251, 251, 251, 0) 100%);
  pointer-events: none;
  z-index: 1;
  margin-right: 0px;

}

.card-carousel{
  display:inline-flex;
  width:99.5%;
  overflow-x:auto;
  margin: 0 auto;  
  padding-right:20px;
  box-sizing:border-box;
}

.manual-carousel-padding{
  height:10px;
  width:30px;
  user-select:none;
}

@media (min-width:350px) {
  .raised-card {
    padding-left: 30px;
    padding-right: 30px;
  }
}



@media (max-width:414px) {

  .fixed-header-search {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }

  .fixed-page-title {
    width: 100%;
    max-width: 365px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

@media (max-width:390px) {

  .fixed-header-search {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
  }

  .fixed-page-title {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}


@media (max-width:375px) {

  .fixed-header-search {
    width: 100%;
    max-width: 325px;
    margin: 0 auto;
  }

  .fixed-page-title {
    width: 100%;
    max-width: 325px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

@media (max-width:320px) {

  .fixed-header-search {
    width: 100%;
    max-width: 270px;
    margin: 0 auto;
  }

  .fixed-page-title {
    width: 100%;
    max-width: 270px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}


@media (min-width:415px) {
  .fixed-header-search {
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
  }

  .fixed-page-title {
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

@media (min-width:768px) {
  .fixed-header-search {
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
  }
  .fixed-page-title {
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

@media (min-width:1024px) {

  .fixed-header-search {
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
  }
  .fixed-page-title {
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

}


@media (min-width:1440px) {

  .fixed-header-search {
    width: 100%;
    max-width: 576px;
    margin: 0 auto;
  }

  .fixed-page-title {
    width: 100%;
    max-width: 576px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

@media (min-width:1850px) {

  .fixed-header-search {
    width: 100%;
    max-width: 740px;
    margin: 0 auto;
  }

  .fixed-page-title {
    width: 100%;
    max-width: 740px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

}



  .invisible-card {
    max-width: 678px;
    width: 100%;
    height: 10px;
    margin: 0 auto;
    margin-bottom: -10px;
  }

  .raised-card-title {
    text-align: left;
    font-size: 18px;
    margin-bottom: 0px;
    font-weight: normal;
  }

  .raised-card-title-large {
    text-align: left;
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: normal;
    margin-bottom: 8px;
  }

  @media (max-width:320px) {
    .raised-card {
      padding: 10px;
    }

    .raised-card-title-large {
      font-size: 18px;
    }
  }

  .raised-card-text {
    text-align: left;
    font-size: 13px;
    font-weight: normal;
    ////-webkit-text-stroke-width: thin;
  }

  .raised-card-subtext {
    text-align: left;
    font-size: 12px;
    font-weight: normal;
  }

  .raised-card-content {
    margin-top: 20px;
  }

  .card-input-label {
    font-size: 14px !important;
    font-family: CircularStd !important;
    color: #203757 !important;
    text-align: left;
    width: 100%;
  }

  .card-input-label-help {
    color: #666F78 !important;
    font-size: 12px !important;
    margin-top: -6px;
    margin-bottom: 4px;
    width: 100%;
    text-align: left;
    font-family: 'ProximaNova';
  }

  .ngx-slider .ngx-slider-selection {
    background: #c50084 !important;
  }

  .ngx-slider .ngx-slider-pointer {
    background: #c50084 !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 12px !important;
    top: -10px !important;
  }

  .ngx-slider .ngx-slider-pointer::after {
    background: white !important;
    top: 8px !important;
    left: 8px !important;
  }

  .ngx-slider-floor {
    opacity: 0 !important;
  }

  .ngx-slider-ceil {
    opacity: 0 !important;
  }

  .ngx-slider .ngx-slider-bubble {
    font-size: 16px !important;
    font-family: 'CircularStd' !important;
    //-webkit-text-stroke: thin !important;
    color: #203757 !important;
  }

  .single-slider-pink ngx-slider .ngx-slider-selection-bar {
    visibility: visible !important;
  }

  .disclaimer-text {
    font-size: 10px;
    color: #666F78;
    text-align: justify;
    padding-top: 250px;
  }

  a {
    color: #c50084;
    text-decoration: none;
  }

  .form-group {
    margin-bottom: 1rem;
    display: block;
    font-size: 1rem;
  }

  .form-group label {
    font-family: CircularStd;
    font-size: 13px;
    color: #203757;
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  .form-group input {
    font-family: CircularStd;
    font-size: 14px;
    color: black;
    border-radius: 4px;
    background-color: rgb(232, 240, 254);
  }

  .form-group input:focus {
    outline-offset: 0px;
  }

  .form-group input:-internal-autofill-selected {
    appearance: menulist-button;
    background-color: rgb(232, 240, 254) !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
  }

  .input-group {
    position: sticky;
    max-width: unset;
    width: unset;
    flex-wrap: wrap;
    display: flex;
    align-items: stretch;
  }

  .form-control {
    height: 50px;
    width: 100%;
    position: relative;
    flex: 1 1 auto;
    min-width: 0;
    font-size: 14px;
    border-radius: 4px;
    border-color: #cfd4da;
    background: #FFFFFF;
    border: 1px solid #CFD4DA;
    box-sizing: border-box;
    left: 0%;
    right: 0%;
    top: 32.39%;
    bottom: 0%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    overflow: visible;
    margin: 0;
    padding: 6px 12px;
  }

  .form-error {
    border: 1px solid red !important;
    border-color: red !important;
    outline: none !important;
    background-color: white !important;
  }

  .form-control:hover {
    background: #FFFFFF;
    border: 1px solid #203757;
    box-sizing: border-box;
    border-radius: 6px;
    left: 0%;
    right: 0%;
    top: 32.39%;
    bottom: 0%;
  }

  .form-control:focus {
    background: #FFFFFF;
    border: 1px solid #203757;
    box-sizing: border-box;
    border-radius: 6px;
    left: 0%;
    right: 0%;
    top: 32.39%;
    bottom: 0%;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .fixed-bar {
    position: absolute;
    background-color: #fbfbfb;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 10px 20px;
    border-top: 1px solid #ebebeb;
    box-sizing: border-box;
  }

  .onfido-sdk-ui-Welcome-text {
    font-family: 'CircularStd';
  }

  .onfido-sdk-ui-PageTitle-title {
    font-family: 'CircularStd';
  }


.access-denied {
  font-family: CircularStd;
  font-size: 12px;
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

